export default {
  it: {
    common: {
      thisWeek: "Questa settimana",
      lastWeek: "La scorsa settimana",
      lastNumberOfDays: "Ultimi {numberOfDays} giorni",
      thisMonth: "Questo mese",
    },
    subAccounts: "Sotto-accounts",
    submitFeedback: "Invia un feedback",
    createSubAccount: "Crea sotto-account",
    viewScheduleReports: "Visualizza report programmati",
    searchSubAccount: "Cerca per sottoconto",
    columns: "Colonne",
    sortBy: "Ordina per",
    selectColumns: "Seleziona colonne",
    maxColumns: "Massimo 8 colonne alla volta",
    applyColumns: "Applica colonne",
    activeUsers: "Utenti attivi",
    appointments: "Appuntamenti",
    contacts: "Contatti",
    emails: "Emails",
    textMessages: "messaggi di testo",
    calls: "Chiamate",
    submissions: "Invii",
    reviews: "Recensioni",
    sitesCreated: "Siti creati",
    name: "Nome",
    formSubmissions: "Sottomissione del modulo",
    surveySubmissions: "Sottomissioni del sondaggio",
    facebookFormSubmissions: "Iscrizioni al modulo di Facebook",
    funnels: "Imbuto",
    websites: "Siti web",
    forms: "Forme",
    surveys: "Sondaggi",
    highestFirst: "Più alto prima",
    lowestFirst: "Più basso per primo",
    orderBy: "Ordina per",
    noMoreLocations: "Non sono state trovate altre posizioni",
    preparingReport: "Stiamo preparando il tuo rapporto",
    preparingReportDesc:
      "Non chiudere o ricaricare questa finestra mentre l'esportazione è in corso",
    outOf: "fuori",
    subAccountsExported: "Account secondari esportati",
    close: "Chiudi",
    scheduledForDeletion: "Programmato per la cancellazione",
    readyToSell: "Pronto per vendere",
    closedWon: "Chiuso (Vinto)",
    wpSaleSuccessful: "La vendita di WordPress è un successo",
    wpNotSold: "WordPress non venduto ancora",
    yxtSaleSuccessful: "La vendita di Yext è un successo",
    yxtNotSold: "Yext non è ancora stato venduto",
    saasSubInactive: "La sottoscrizione SaaS è inattiva",
    saasSubTrialing: "La sottoscrizione SaaS è in fase di prova",
    saasSubActive: "La sottoscrizione SaaS è attiva",
    emailRebillingActive: "L'email di addebito automatico è attiva",
    emailRebillingInactive: "La riconsegna per email è disattivata",
    twilioRebillingActive: "Twilio il rinnovo automatico è attivo",
    twilioRebillingInactive: "Il riaddebito di Twilio è disattivato",
    wpRebillingActive: "WhatsApp rebilling è attivo",
    wpRebillingInactive: "Il rebilling di WhatsApp è inattivo",
    saasModeActivated: "La modalità SaaS è attivata",
    paymentPending: "Il pagamento è in sospeso",
    saasModeNotActivated: "Il modo SaaS non è attivato",
    managedByLCPhone: "Gestito da LC Phone",
    migrationToLCPhone: "Sotto migrazione verso LC Phone",
    notManagedByLCPhone: "Non gestito da LC Phone",
    managedByLCEmail: "Amministrato da LC Email",
    notMangedByLCEmail: "Non gestito da LC Email",
    remainingWalletBalance: "Saldo residuo del portafoglio di questa posizione SaaS",
    reports: "Rapporti",
    switchToSubAccount: "Passa a Sotto-Account",
    manageClient: "Gestire il cliente",
    subAccountDeleted: "Questo sub-account verrà eliminato il",
    subAccountDeletedInMinute: "Questo sotto-account verrà eliminato tra un minuto",
    changedMind: "se hai cambiato idea",
    clickHere: "Fai clic qui",
    subAccountPaused:
      "Questo subaccount è in pausa. I tuoi clienti non possono effettuare l'accesso, ma l'agenzia può farlo. Per ripristinare questo account",
    subAccountRestricted: "Questo sotto-account è in pausa in attesa di approvazione",
    subAccountVerificationPending:
      "Questo sottoconto è in pausa a causa della verifica in sospeso dell'email e del numero di telefono da parte dell'amministratore del sottoconto",
    subAccountDeclined:
      "Questo sotto-account è in pausa a causa di una richiesta di approvazione respinta. Puoi comunque riattivare questo sotto-account da",
    here: "qui",
    reviewAndResume: "per rivedere e riprendere questo sotto-account",
    incomingCalls: "Chiamate in arrivo",
    outgoingCalls: "Chiamate in uscita",
    totalTalktime: "Totale del tempo di conversazione",
    incomingEmails: "Email in arrivo",
    outgoingEmails: "Email in uscita",
    incomingSms: "Nuovi messaggi ricevuti",
    outgoingSms: "Messaggi di testo in uscita",
    contactsCreated: "Numero di contatti creati",
    appointmentsBooked: "Numero di appuntamenti prenotati",
    totalFormSubmissions: "Totale invio form",
    totalSurveySubmissions: "Totale delle risposte al sondaggio",
    totalFacebookFormSubmissions: "Numero totale di invii del modulo su Facebook",
    incomingGMBMessages: "Messaggi GBP in arrivo",
    outgoingGMBMessages: "Messaggi GBP in uscita",
    gmbMessages: "Messaggi GBP",
    fbMessages: "Messaggi di Facebook",
    incomingFbMessages: "Messaggi Facebook in arrivo",
    outgoingFbMessages: "Messaggi di Facebook in uscita",
    igMessages: "Messaggi Instagram",
    incomingIgMessages: "Messaggi Instagram in arrivo",
    outgoingIgMessages: "Messaggi Instagram in uscita",
    totalReviews: "Recensioni totali",
    positiveReviews: "Recensioni positive",
    negativeReviews: "Recensioni negative",
    back: "Indietro",
    selectDashboards: "Seleziona dashboard",
    next: "Successivo",
    backToSubAccounts: "Torna alle Sottoaccount",
    pauseSchedule: "Programma di pausa",
    resumeSchedule: "Riprendi l'orario",
    editSchedule: "Modifica programma",
    addSchedule: "Aggiungi Programmazione",
    selectReportToAdd: "Seleziona i rapporti da aggiungere all'email",
    selectAll: "Seleziona tutto",
    pauseScheduleConfirm: "Sei sicuro/a di voler mettere in pausa la pianificazione del report?",
    resumeScheduleConfirm: "Sei sicuro di voler riprendere il programma di report?",
    tasks: "Compiti",
    conversations: "Conversazioni",
    users: "Utenti",
    dealOverview: "Panoramica dell'accordo",
    confirmAction: "Conferma azione",
    yes: "Sì",
    no: "No",
    reportDetails: "Dettagli del report",
    reportCreatedOn: "Rapporto creato il",
    searchByEmail: "Cerca per email",
    lastActivity: "Ultima attività",
    noRecords: "Nessun risultato trovato",
    scheduleReport: "Programma rapporto",
    schedule: "Programma",
    save: "Salva",
    emailSubject: "Oggetto della email",
    frequency: "Frequenza",
    daily: "quotidiano",
    weekly: "Settimanale",
    monthly: "mensile",
    nextScheduleDate: "Prossima data programmata",
    startingOn: "Iniziando da",
    timeOfDay: "Orario della giornata",
    reportsToTimezone: "Il report verrà inviato nel fuso orario del sottaccount",
    selectRecipients: "Seleziona i destinatari",
    invalidEmail: "Hai inserito un indirizzo email non valido",
    testEmail: "Email di prova",
    selectTestRecipients: "Seleziona i destinatari del test",
    setupSubject: "Imposta il soggetto sopra per testare il report",
    sendTest: "Invia prova",
    scheduleSimilarSchedules: "Pianifica un report simile per gli altri sottoconti",
    accountSummary: "Riepilogo dell'account",
    scheduleList: "Elenco di programmazione",
    subAccountName: "Nome sottocategoria",
    nextRunDate: "Prossima data di esecuzione",
    reportSectionsAdded: "Sezioni del report aggiunte",
    status: "Stato",
    scheduleAction: "Azione di programmazione",
    cancel: "Annulla",
    pleaseWait: "Per favore, aspetta",
    confirm: "Conferma",
    booked: "Prenotato",
    confirmed: "Confermato",
    showed: "mostrò",
    noShow: "No Show",
    cancelled: "Annullato",
    unread: "Non letto",
    engagement: "Fidanzamento",
    reaction: "Reazione",
    deliverySummary: "Riepilogo della consegna",
    successfulDelivery: "Consegna riuscita",
    opened: "Aperto",
    clicked: "Cliccato",
    replied: "Risposta",
    unsubscribed: "Annullato la sottoscrizione",
    complained: "Lamentato",
    failed: "Fallito",
    open: "Aperto",
    won: "Vinto",
    abandoned: "Abbandonato",
    lost: "Perso",
    sent: "Inviato",
    delivered: "Consegnato",
    faceBookLeadForm: "Modulo di contatto di Facebook",
    overdue: "ritardato",
    reviewsReceived: "Recensioni ricevute",
    formBuilders: "Costruttori di Moduli",
    switchToSaaS: "Passare a SaaS",
    numberOfContacts: "Numero di contatti",
    numberOfAppointments: "Numero di appuntamenti",
    outreach: "Coinvolgimento esterno",
    notSubAccountUser: "Non un utente di un account secondario",
    searchSubAccName: "Cerca per nome del sotto-account",
    recipients: "Destinatari",
    scheduleCreated: "Programma di report Email creato per",
    scheduleUpdated: "Programma di invio rapporto email aggiornato per",
    scheduleNotCreated: "Il programma per l'invio di report via email non è stato creato per",
    scheduleNotUpdated:
      "La pianificazione di aggiornamento degli aggiornamenti dell'email report non è stata possibile per",
    total: "Totale",
    inbound: "Ingresso",
    outbound: "Uscita",
    totalEmails: "Email totali",
    inboundEmails: "Email in arrivo",
    outboundEmails: "Email in uscita",
    totalTextMessages: "Totale messaggi di testo",
    inboundTextMessages: "Messaggi di testo in ingresso",
    outboundTextMessages: "Messaggi di testo in uscita",
    totalCalls: "Chiamate totali",
    inboundCalls: "Chiamate in ingresso",
    outboundCalls: "Chiamate in uscita",
    faceBookLeadFormSubmissions: "Moduli di registrazione Facebook",
    inboundGMBMessages: "Inbound messaggi GBP",
    outboundGMBMessages: "Messaggi in uscita GBP",
    totalIgMessages: "Totale messaggi Instagram",
    inboundIgMessages: "Messaggi Instagram in entrata",
    outboundIgMessages: "Messaggi Instagram in uscita",
    totalfbMessages: "Messaggi totali di Facebook",
    inboundfbMessages: "Messaggi in entrata su Facebook",
    outboundfbMessages: "Messaggi Facebook in uscita",
    sitesCreatedFunnels: "Siti Creati - Funnel",
    sitesCreatedWebsites: "Siti creati - Siti web",
    sitesCreatedForms: "Siti creati - Forme",
    sitesCreatedSurveys: "Siti Creati - Sondaggi",
    googleReviews: "Recensioni di Google",
    facebookReviews: "Recensioni su Facebook",
    inboundCallDuration: "Durata della chiamata in entrata (ore)",
    outboundCallDuration: "Durata della chiamata in uscita (ore)",
    pleaseSelect: "Per favore, seleziona",
    subAccountIsDeleted: "Questo sotto",
    subAccountIsPaused: "Questo sub-account è stato messo in pausa",
    success: "Successo",
    error: "Errore",
    testEmailSent: "Email di prova inviata",
    testEmailNotSent: "Test non è possibile inviare l'email",
    typeToSearch: "Digita per cercare",
    reviewRequest: "Richieste di revisione",
    pendingTransferDescription: "Altre agenzie hanno richiesto di trasferire sottoconti a te",
    pendingTransferHeading: "Hai delle richieste di trasferimento di sub-account in sospeso",
    subAccountTransfer: "Trasferimenti sub-account",
    manageSubAccTransfer: "Gestire le richieste di trasferimento del tuo sub-account",
    subAccRequest: "Richieste di sotto-account {tabName}",
    receive: "Ricevi",
    send: "Inviare",
    release: "Rilascio",
    requests: "{count} Richieste",
    subAccLimit: "Limite di utilizzo dei sub-account raggiunto!",
    subAccPlanLimit: "Hai utilizzato 3/3 sottoclassi consentite dal tuo piano attuale",
    upgradeTime: "È ora di fare un aggiornamento!",
    upgradeHigher:
      "Si prega di passare a un piano superiore per accettare il trasferimento del sub-account",
    browse: "Sfoglia Piani",
    pendingTransferTitle:
      "Una richiesta di trasferimento per questo sotto-account è già in sospeso",
    unableTransfer: "Impossibile trasferire il sotto-account a questa agenzia",
    hippaErrorDescription:
      "Il sub-account che stai cercando di trasferire è abilitato all'HIPAA e non può essere trasferito a un'agenzia non-HIPAA",
    pendingTransferErrorDescription:
      "Cortesemente cancellare la richiesta di trasferimento già esistente per questo sotto-account prima di procedere ad aprine una nuova",
    receivingAgency: "Ente ricevente",
    releasingAgency: "Agenzia di rilascio",
    noOfContacts: "Numero di contatti",
    requestedDate: "Data richiesta",
    action: "Azione",
    cancelTransfer: "Annulla trasferimento",
    decline: "Declino",
    cancelTransferRequest: "Annulla la richiesta di trasferimento del sub-account?",
    cancelRequestDescription:
      "L'agenzia ricevente non vedrà più questa richiesta di trasferimento e il sottoconto {name} non verrà trasferito",
    declineRequest: "Rifiutare la richiesta di trasferimento del sottoconto?",
    declineRequestDescription:
      "La richiesta verrà rifiutata e il sub-account {name} non verrà trasferito alla tua agenzia",
    approveTransfer: "Approvare il trasferimento del sottoconto alla tua agenzia?",
    approveTransferDescription: "Il sottoconto di {name} sarà trasferito alla tua agenzia",
    transferNow: "Trasferire Ora",
    cancelNow: "Annulla adesso",
    transferErrorDetails:
      "Il sottocconto che stai cercando di trasferire non può essere trasferito",
    cancelRequest: "Annulla richiesta",
    received: "Ricevuto",
    approve: "Approvare",
    goBack: "Torna indietro",
    done: "Fatto",
    subAccountTransferred: "Il sub-account è stato trasferito con successo!",
    subAccountTransferDescription:
      "Si prega di notare che anche se il sotto-account è stato trasferito, alcuni asset come utenti, numeri di telefono, account Mailgun, elenchi Yext, ecc. potrebbero ancora impiegare 10-15 minuti per essere visualizzati nella destinazione",
    activeSubscriptionsAlert:
      "La posizione ha abbonamenti attivi. Si prega di cancellare tutti gli abbonamenti prima di trasferire la posizione. Elenco degli abbonamenti attivi: {subscriptions}",
    emailEngagement: "Coinvolgimento via email",
    emailReaction: "Reazione via email",
    emailDeliverySummary: "Riepilogo di consegna dell'email",
    facebook: "Facebook",
    sms: "SMS",
    instagram: "Instagram",
    accountSummarySubAccount: "Account Summary - {subAccountName}",
    test: "Test",
    scheduleExists: "Schedule Exists",
    aToZ: "A - Z",
    zToA: "Z - A",
    onlyAdminUsersCanAddNewSubAccounts: "Only Admin users can add new sub-account's.",
    locationName: "Location Name",
    subAccountDeletedIfChangedMind:
      "This Sub-Account will be deleted on {time}, if you've changed your mind",
    eject: "Eiettare",
    unableEjectCancel: "Impossibile annullare la richiesta di espulsione del sub-account",
    EjectErrorDetails:
      "La richiesta di espulsione del Sotto-Account che stai cercando di annullare non può essere annullata",
    cancelEjectRequest: "Annullare la richiesta di rimozione sottoprofilo?",
    cancelEjectRequestDescription:
      "L'utente selezionato non potrà più creare una nuova agenzia dal Sotto-Account {name}",
  },
};
