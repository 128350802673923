export default {
  pt: {
    common: {
      thisWeek: "Esta semana",
      lastWeek: "Última semana",
      lastNumberOfDays: "Últimos {numberOfDays} dias",
      thisMonth: "Este mês",
    },
    subAccounts: "Subcontas",
    submitFeedback: "Enviar Comentários",
    createSubAccount: "Criar subconta",
    viewScheduleReports: "Ver Relatórios Agendados",
    searchSubAccount: "Pesquisar por subconta",
    columns: "Colunas",
    sortBy: "Ordenar por",
    selectColumns: "Selecionar colunas",
    maxColumns: "Máximo de 8 colunas de cada vez",
    applyColumns: "Aplicar colunas",
    activeUsers: "Utilizadores ativos",
    appointments: "Consultas",
    contacts: "Contactos",
    emails: "Emails",
    textMessages: "Mensagens de texto",
    calls: "Chamadas",
    submissions: "Submissões",
    reviews: "Análises",
    sitesCreated: "Sites Criados",
    name: "Nome",
    formSubmissions: "Envio de formulários",
    surveySubmissions: "Envio de Inquéritos",
    facebookFormSubmissions: "Submissões de formulários no Facebook",
    funnels: "Funis",
    websites: "Websites",
    forms: "Formas",
    surveys: "Inquéritos",
    highestFirst: "Primeiro mais alto",
    lowestFirst: "Menor primeiro",
    orderBy: "Ordenar por",
    noMoreLocations: "Não foram encontradas mais localizações",
    preparingReport: "Estamos a preparar o teu relatório",
    preparingReportDesc:
      "Não feche nem recarregue esta janela enquanto a exportação estiver em progresso",
    outOf: "fora de",
    subAccountsExported: "Subcontas exportadas",
    close: "Fechar",
    scheduledForDeletion: "Agendado para exclusão",
    readyToSell: "Pronto para vender",
    closedWon: "Fechado (Ganho)",
    wpSaleSuccessful: "A venda do WordPress foi um sucesso",
    wpNotSold: "WordPress ainda não foi vendido",
    yxtSaleSuccessful: "A venda do Yext é um sucesso",
    yxtNotSold: "Yext ainda não foi vendido",
    saasSubInactive: "A subscrição de SaaS está inativa",
    saasSubTrialing: "A subscrição de SaaS está em fase de teste",
    saasSubActive: "A subscrição SaaS está ativa",
    emailRebillingActive: "O reenvio de faturas por email está ativo",
    emailRebillingInactive: "A faturação por email está inativa",
    twilioRebillingActive: "A cobrança do Twilio está ativa",
    twilioRebillingInactive: "A faturação da Twilio está inativa",
    wpRebillingActive: "O sistema de cobrança automática do WhatsApp está ativo",
    wpRebillingInactive: "O rebilling do WhatsApp está inativo",
    saasModeActivated: "O modo SaaS está ativado",
    paymentPending: "O pagamento está pendente",
    saasModeNotActivated: "O modo SaaS não está ativado",
    managedByLCPhone: "Gerido por LC Phone",
    migrationToLCPhone: "Em migração para o telefone LC",
    notManagedByLCPhone: "Não gerido pela LC Phone",
    managedByLCEmail: "Gerenciado por LC Email",
    notMangedByLCEmail: "Não gerido por e-mail da LC",
    remainingWalletBalance: "Saldo restante da carteira deste local de SaaS",
    reports: "Relatórios",
    switchToSubAccount: "Mudar para Subconta",
    manageClient: "Gerir Cliente",
    subAccountDeleted: "Esta subconta será eliminada em",
    subAccountDeletedInMinute: "Esta subconta será apagada em um minuto",
    changedMind: "Se mudaste de ideias",
    clickHere: "Clique aqui",
    subAccountPaused:
      "Esta subconta está pausada. Os seus clientes não podem fazer login, mas a agência pode. Para retomar esta conta...",
    subAccountRestricted: "Esta subconta está pausada devido a uma aprovação pendente",
    subAccountVerificationPending:
      "Esta subconta está pausada devido à verificação pendente de e-mail e telefone pelo administrador da subconta",
    subAccountDeclined:
      "Esta subconta está pausada devido a um pedido de aprovação recusado. Ainda é possível retomar esta subconta a partir de",
    here: "aqui",
    reviewAndResume: "Rever e retomar esta subconta",
    incomingCalls: "Chamadas recebidas",
    outgoingCalls: "Chamadas em saída",
    totalTalktime: "Tempo total de chamada",
    incomingEmails: "Emails recebidos",
    outgoingEmails: "Emails enviados",
    incomingSms: "Novas mensagens de texto chegando",
    outgoingSms: "Mensagens de texto enviadas",
    contactsCreated: "Número de contatos criados",
    appointmentsBooked: "Número de consultas agendadas",
    totalFormSubmissions: "Submissões totais do formulário",
    totalSurveySubmissions: "Total de respostas no questionário",
    totalFacebookFormSubmissions: "Submissões totais de formulários do Facebook",
    incomingGMBMessages: "Mensagens GBP Recebidas",
    outgoingGMBMessages: "Mensagens de saída em GBP",
    gmbMessages: "Mensagens GBP",
    fbMessages: "Mensagens do Facebook",
    incomingFbMessages: "Mensagens do Facebook recebidas",
    outgoingFbMessages: "Mensagens de saída do Facebook",
    igMessages: "Mensagens do Instagram",
    incomingIgMessages: "Mensagens do Instagram recebidas",
    outgoingIgMessages: "Mensagens enviadas no Instagram",
    totalReviews: "Total de avaliações",
    positiveReviews: "Avaliações positivas",
    negativeReviews: "Avaliações negativas",
    back: "Costas",
    selectDashboards: "Selecionar painéis de controle",
    next: "Próximo",
    backToSubAccounts: "Voltar para Subcontas",
    pauseSchedule: "Pausar agenda",
    resumeSchedule: "Retomar o horário",
    editSchedule: "Editar Agenda",
    addSchedule: "Adicionar horário",
    selectReportToAdd: "Selecione os relatórios para adicionar no email",
    selectAll: "Selecionar tudo",
    pauseScheduleConfirm: "Tem a certeza de que deseja pausar o calendário do relatório?",
    resumeScheduleConfirm: "Tem a certeza de que deseja retomar o agendamento do relatório?",
    tasks: "Tarefas",
    conversations: "Conversas",
    users: "Utilizadores",
    dealOverview: "Visão geral do acordo",
    confirmAction: "Confirmar Ação",
    yes: "Sim",
    no: "Não",
    reportDetails: "Detalhes do Relatório",
    reportCreatedOn: "Relatório criado em",
    searchByEmail: "Procurar por email",
    lastActivity: "Última atividade",
    noRecords: "Nenhum registro encontrado",
    scheduleReport: "Relatório de Horários",
    schedule: "Horário",
    save: "Salvar",
    emailSubject: "Assunto do email",
    frequency: "Frequência",
    daily: "diário",
    weekly: "Semanalmente",
    monthly: "Mensalmente",
    nextScheduleDate: "Próxima data agendada",
    startingOn: "Começando em",
    timeOfDay: "Hora do dia",
    reportsToTimezone: "O relatório será enviado no fuso horário da subconta",
    selectRecipients: "Selecionar destinatários",
    invalidEmail: "Você inseriu um endereço de email inválido",
    testEmail: "Email de teste",
    selectTestRecipients: "Selecionar destinatários de teste",
    setupSubject: "Configure o assunto acima para testar o relatório",
    sendTest: "Enviar teste",
    scheduleSimilarSchedules: "Agende um relatório semelhante para outras subcontas",
    accountSummary: "Resumo da conta",
    scheduleList: "Lista de Horários",
    subAccountName: "Nome da subconta",
    nextRunDate: "Próxima Data de Execução",
    reportSectionsAdded: "Secções do Relatório Adicionadas",
    status: "Estado",
    scheduleAction: "Agenda de Ação",
    cancel: "Cancelar",
    pleaseWait: "Por favor, aguarda",
    confirm: "Confirmar",
    booked: "Reservado",
    confirmed: "Confirmado",
    showed: "mostrou",
    noShow: "ausência não justificada",
    cancelled: "cancelado",
    unread: "Não lido",
    engagement: "Noivado",
    reaction: "Reação",
    deliverySummary: "Resumo da Entrega",
    successfulDelivery: "Entrega bem-sucedida",
    opened: "Aberto",
    clicked: "Clicado",
    replied: "Respondeu",
    unsubscribed: "Desinscrito",
    complained: "queixou-se",
    failed: "Falhou",
    open: "Aberto",
    won: "Ganhou",
    abandoned: "Abandonado",
    lost: "Perdido",
    sent: "Enviado",
    delivered: "Entregue",
    faceBookLeadForm: "Formulário de Captação de Leads do Facebook",
    overdue: "Atrasado",
    reviewsReceived: "Avaliações recebidas",
    formBuilders: "Construtores de formulários",
    switchToSaaS: "Mudar para SaaS",
    numberOfContacts: "Número de contatos",
    numberOfAppointments: "Número de Agendamentos",
    outreach: "Divulgação",
    notSubAccountUser: "Não é um utilizador de subconta",
    searchSubAccName: "Procurar pelo nome da subconta",
    recipients: "Destinatários",
    scheduleCreated: "Agendamento de Relatório por Email criado para",
    scheduleUpdated: "Agendamento de relatório por e-mail atualizado para",
    scheduleNotCreated: "Não foi possível criar o agendamento do relatório por e-mail",
    scheduleNotUpdated: "O agendamento do relatório de e-mail não pôde ser atualizado para",
    total: "Total",
    inbound: "Entrada",
    outbound: "Partida ou Saída",
    totalEmails: "Total de emails",
    inboundEmails: "Emails recebidos",
    outboundEmails: "Emails de saída",
    totalTextMessages: "Total de mensagens de texto",
    inboundTextMessages: "Mensagens de texto de entrada",
    outboundTextMessages: "Mensagens de texto de saída",
    totalCalls: "Total de Chamadas",
    inboundCalls: "Chamadas de entrada",
    outboundCalls: "Chamadas de saída",
    faceBookLeadFormSubmissions: "Submissões de formulário de leads do Facebook",
    inboundGMBMessages: "Mensagens recebidas em GBP",
    outboundGMBMessages: "Mensagens GBP de saída",
    totalIgMessages: "Mensagens Totais do Instagram",
    inboundIgMessages: "Mensagens do Instagram recebidas",
    outboundIgMessages: "Mensagens de saída do Instagram",
    totalfbMessages: "Total de Mensagens no Facebook",
    inboundfbMessages: "Mensagens recebidas no Facebook Inbound",
    outboundfbMessages: "Mensagens de saída no Facebook",
    sitesCreatedFunnels: "Sites Criados - Funis",
    sitesCreatedWebsites: "Sites Criados - Websites",
    sitesCreatedForms: "Sites Criados - Formulários",
    sitesCreatedSurveys: "Websites Criados - Inquéritos",
    googleReviews: "Avaliações do Google",
    facebookReviews: "Avaliações do Facebook",
    inboundCallDuration: "Duração de chamadas recebidas (horas)",
    outboundCallDuration: "Duração da Chamada de Saída (horas)",
    pleaseSelect: "Por favor selecionar",
    subAccountIsDeleted: "Esta subconta foi eliminada",
    subAccountIsPaused: "Esta subconta foi pausada",
    success: "Sucesso",
    error: "Erro",
    testEmailSent: "Email de teste enviado",
    testEmailNotSent: "O email de teste não pôde ser enviado",
    typeToSearch: "Escreva para pesquisar",
    reviewRequest: "Pedidos de Revisão",
    pendingTransferDescription: "Outras agências solicitaram transferir sub-contas para si",
    pendingTransferHeading: "Tem pedidos de transferência de sub-contas pendentes",
    subAccountTransfer: "Transferências entre subcontas",
    manageSubAccTransfer: "Gerir os pedidos de transferência da sua subconta",
    subAccRequest: "Pedidos de Subconta {tabName}",
    receive: "Receber",
    send: "Enviar",
    release: "Lançamento",
    requests: "{count} Pedidos",
    subAccLimit: "Limite de utilização de subcontas atingido!",
    subAccPlanLimit: "Você utilizou os 3/3 subcontas permitidos no seu plano atual",
    upgradeTime: "É hora de uma atualização!",
    upgradeHigher:
      "Por favor, faça o upgrade para um plano superior para aceitar a transferência da sub-conta",
    browse: "Ver Planos",
    pendingTransferTitle: "Um pedido de transferência para esta subconta já está pendente",
    unableTransfer: "Não é possível transferir a subconta para esta agência",
    hippaErrorDescription:
      "A subconta que está a tentar transferir está habilitada para HIPAA e não pode ser transferida para uma entidade não-HIPAA",
    pendingTransferErrorDescription:
      "Por favor, cancele o pedido de transferência já existente para esta subconta antes de prosseguir com um novo",
    receivingAgency: "Agência Receptora",
    releasingAgency: "Agência de Libertação",
    noOfContacts: "Número de Contactos",
    requestedDate: "Data solicitada",
    action: "Ação",
    cancelTransfer: "Cancelar Transferência",
    decline: "Decadência",
    cancelTransferRequest: "Cancelar pedido de transferência da subconta?",
    cancelRequestDescription:
      "A agência receptora já não terá acesso a este pedido de transferência e a subconta {name} não será transferida",
    declineRequest: "Recusar pedido de transferência de subconta?",
    declineRequestDescription:
      "O pedido será recusado e a subconta {name} não será transferida para a sua agência",
    approveTransfer: "Aprovar a transferência da subconta para a sua agência?",
    approveTransferDescription: "A subconta de {name} será transferida para a sua agência",
    transferNow: "Transferir Agora",
    cancelNow: "Cancelar agora",
    transferErrorDetails: "A subconta que está a tentar transferir não pode ser transferida",
    cancelRequest: "Cancelar Pedido",
    received: "Recebido",
    approve: "Aprovar",
    goBack: "Volte atrás",
    done: "Feito",
    subAccountTransferred: "A sub-conta foi transferida com sucesso!",
    subAccountTransferDescription:
      "Por favor note que mesmo que a subconta tenha sido transferida, alguns ativos como utilizadores, números de telefone, contas do Mailgun, listagens do Yext, entre outros, podem demorar entre 10 a 15 minutos a refletir no destino",
    activeSubscriptionsAlert:
      "O local tem subscrições ativas. Por favor, cancele todas as subscrições antes de transferir o local. Lista de subscrições ativas: {subscriptions}",
    emailEngagement: "Envolvimento por email",
    emailReaction: "Reação por email",
    emailDeliverySummary: "Sumário de Entrega de Email",
    facebook: "Facebook",
    sms: "SMS",
    instagram: "Instagram",
    accountSummarySubAccount: "Account Summary - {subAccountName}",
    test: "Test",
    scheduleExists: "Schedule Exists",
    aToZ: "A - Z",
    zToA: "Z - A",
    onlyAdminUsersCanAddNewSubAccounts: "Only Admin users can add new sub-account's.",
    locationName: "Location Name",
    subAccountDeletedIfChangedMind:
      "This Sub-Account will be deleted on {time}, if you've changed your mind",
    eject: "Expulsar",
    unableEjectCancel: "Não é possível cancelar o pedido de expulsão da sub-conta",
    EjectErrorDetails:
      "O pedido de eliminação da Sub-Conta que está a tentar cancelar não pode ser cancelado",
    cancelEjectRequest: "Cancelar pedido de desvinculação de subconta?",
    cancelEjectRequestDescription:
      "O utilizador selecionado já não poderá criar uma nova agência a partir da Subconta {name}",
  },
};
