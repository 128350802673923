export default {
  fi: {
    common: {
      thisWeek: "Tällä viikolla",
      lastWeek: "Viime viikko",
      lastNumberOfDays: "Viimeiset {numberOfDays} päivää",
      thisMonth: "Tämä kuukausi",
    },
    subAccounts: "Alitilit",
    submitFeedback: "Lähetä palautetta",
    createSubAccount: "Luo alitili",
    viewScheduleReports: "Näytä aikataulutetut raportit",
    searchSubAccount: "Hae ala-tilillä",
    columns: "Sarakkeet",
    sortBy: "Lajittele + Liittyen",
    selectColumns: "Valitse sarakkeet",
    maxColumns: "Kerrallaan enintään 8 saraketta",
    applyColumns: "Sovella sarakkeita",
    activeUsers: "Aktiiviset käyttäjät",
    appointments: "Tapa",
    contacts: "Yhteystiedot",
    emails: "Sähköpostit",
    textMessages: "Tekstiviestit",
    calls: "Soitot",
    submissions: "Osallistumiset",
    reviews: "Arvostelut",
    sitesCreated: "Luodut sivustot",
    name: "Nimi",
    formSubmissions: "Lomakkeen lähetykset",
    surveySubmissions: "Kyselylomakkeiden lähettäminen",
    facebookFormSubmissions: "Facebook-kaavakkeiden lähetykset",
    funnels: "Supista-ahtimet",
    websites: "Verkkosivut",
    forms: "Lomakkeet",
    surveys: "Kyselyt",
    highestFirst: "Korkein ensin",
    lowestFirst: "Alin ensin",
    orderBy: "Tilaa mukaan",
    noMoreLocations: "Ei löytynyt enempää sijainteja",
    preparingReport: "Valmistamme raporttiasi",
    preparingReportDesc: "Älä sulje tai lataa uudelleen tätä ikkunaa, kun vienti on käynnissä",
    outOf: "ulos",
    subAccountsExported: "Alihankintatilit viedään",
    close: "Sulje",
    scheduledForDeletion: "Arvioitu poistoaika",
    readyToSell: "Valmis myytäväksi",
    closedWon: "Suljettu (voitettu)",
    wpSaleSuccessful: "WordPress myynti onnistui",
    wpNotSold: "WordPress ei ole vielä myyty",
    yxtSaleSuccessful: "Yextin myynti on onnistunut",
    yxtNotSold: "Yext ei ole vielä myynnissä",
    saasSubInactive: "SaaS-tilaus on passiivinen",
    saasSubTrialing: "SaaS-tilaus tekee koekäyttöä",
    saasSubActive: "SaaS-tilaus on aktiivinen",
    emailRebillingActive: "Sähköpostin uudelleenlaskutus on aktiivinen",
    emailRebillingInactive: "Sähköpostin laskutus ei ole aktiivinen",
    twilioRebillingActive: "Twilion uudelleenlaskutus on käytössä",
    twilioRebillingInactive: "Twilion uudelleenlaskutus on poissa käytöstä",
    wpRebillingActive: "WhatsApp-laskutus on aktivoitu",
    wpRebillingInactive: "WhatsApp-uudelleenlaskutus on poistettu käytöstä",
    saasModeActivated: "SaaS-tila on aktivoitu",
    paymentPending: "Maksu on odottamassa",
    saasModeNotActivated: "SaaS-tilaa ei ole aktivoitu",
    managedByLCPhone: "Hallinnoi LC-puhelimella",
    migrationToLCPhone: "Muuttossa LC Puhelimeen",
    notManagedByLCPhone: "Ei hallinnoida LC Puhelimen kautta",
    managedByLCEmail: "Hallinnoi LC-sähköpostin avulla",
    notMangedByLCEmail: "Ei hoidettu LC-sähköpostin kautta",
    remainingWalletBalance: "Jäljellä oleva lompakon saldo tässä SaaS-sijainnissa",
    reports: "Raportit",
    switchToSubAccount: "Vaihda alitilille",
    manageClient: "Hallinnoi asiakasta",
    subAccountDeleted: "Tämä ala-tili poistetaan",
    subAccountDeletedInMinute: "Tämä alitili poistetaan minuutin kuluessa",
    changedMind: "jos olette muuttanut mielenne",
    clickHere: "Napsauta tästä",
    subAccountPaused:
      "Tämä alitili on pysähdyksissä. Asiakkaasi eivät pysty kirjautumaan sisään, mutta toimisto pystyy. Jatkaaksesi tätä tiliä",
    subAccountRestricted: "Tämä alitili on pysäytetty odottavan hyväksynnän takia",
    subAccountVerificationPending:
      "Tämä alitili on pysäytetty odottavan sähköposti- ja puhelinvarmennuksen vuoksi alitilin hallinnolta",
    subAccountDeclined:
      "Tämä alitili on pysäytetty hylätyn hyväksyntäpyynnön vuoksi. Voit kuitenkin jatkaa tämän alitilin käyttöä",
    here: "täällä",
    reviewAndResume: "tarkistaa ja jatkaa tätä alitiliä",
    incomingCalls: "Saapuvat puhelut",
    outgoingCalls: "Lähtevät puhelut",
    totalTalktime: "Kokonaispuheluaika",
    incomingEmails: "Saapuvat sähköpostit",
    outgoingEmails: "Lähtevät sähköpostit",
    incomingSms: "Sisääntulevat tekstiviestit",
    outgoingSms: "Lähtevät tekstiviestit",
    contactsCreated: "Luotujen yhteyksien määrä",
    appointmentsBooked: "Varattujen tapaamisien määrä",
    totalFormSubmissions: "Kokonaislomakkeiden lähetykset",
    totalSurveySubmissions: "Kokonaismäärä kyselyvastauksia",
    totalFacebookFormSubmissions: "Kokonaismäärä Facebook-lomakkeiden lähetysten osalta",
    incomingGMBMessages: "Saapuvat GBP-viestit",
    outgoingGMBMessages: "Ulkominä lähetetyt GBP-viestit",
    gmbMessages: "GBP-viestit",
    fbMessages: "Facebook-viestit",
    incomingFbMessages: "Saapuvat Facebook-viestit",
    outgoingFbMessages: "Lähtevät Facebook-viestit",
    igMessages: "Instagram-viestit",
    incomingIgMessages: "Saapuvat Instagram-viestit",
    outgoingIgMessages: "Lähtevät Instagram-viestit",
    totalReviews: "Kokonaisarvostelut",
    positiveReviews: "Positiiviset arvostelut",
    negativeReviews: "Negatiiviset arvostelut",
    back: "Takaisin",
    selectDashboards: "Valitse kojetaulut",
    next: "Seuraava",
    backToSubAccounts: "Palaa alitileihin",
    pauseSchedule: "Taukoaikataulu",
    resumeSchedule: "Jatka aikataulua",
    editSchedule: "Muokkaa aikataulua",
    addSchedule: "Lisää aikataulu",
    selectReportToAdd: "Valitse raportit lisättäväksi sähköpostiin",
    selectAll: "Valitse kaikki",
    pauseScheduleConfirm: "Haluatko varmasti keskeyttää raportin ajastuksen?",
    resumeScheduleConfirm: "Oletko varma, että haluat jatkaa raporttiaikataulua?",
    tasks: "Tehtävät",
    conversations: "Keskustelut",
    users: "Käyttäjät",
    dealOverview: "Sopimuskatsaus",
    confirmAction: "Vahvista toiminto",
    yes: "Kyllä",
    no: "Ei",
    reportDetails: "Raportin tiedot",
    reportCreatedOn: "Raportti luotu",
    searchByEmail: "Hae sähköpostin perusteella",
    lastActivity: "Viimeinen aktiviteetti",
    noRecords: "Ei tuloksia löytynyt",
    scheduleReport: "Aikatauluta raportti",
    schedule: "aikataulu",
    save: "Tallentaa",
    emailSubject: "Aihe",
    frequency: "Taajuus",
    daily: "Päivittäinen",
    weekly: "Viikoittainen",
    monthly: "Kuukausi",
    nextScheduleDate: "Seuraava aikataulutettu päivämäärä",
    startingOn: "Aloitetaan",
    timeOfDay: "Ajanjakso",
    reportsToTimezone: "Raportti lähetetään alikäyttäjän aikavyöhykkeen mukaisesti",
    selectRecipients: "Valitse vastaanottajat",
    invalidEmail: "Olet syöttänyt virheellisen sähköpostiosoitteen",
    testEmail: "Koemailjut",
    selectTestRecipients: "Valitse testin vastaanottajat",
    setupSubject: "Aseta aihe yläpuolelle raportin testaamista varten",
    sendTest: "Lähetä testi",
    scheduleSimilarSchedules: "Aikatauluta vastaava raportti muille alatileille",
    accountSummary: "Tilin yhteenveto",
    scheduleList: "Aikataululuettelo",
    subAccountName: "Alitilin nimi",
    nextRunDate: "Seuraava suorituspäivä",
    reportSectionsAdded: "Raporttiosiot lisätty",
    status: "Tila",
    scheduleAction: "Aikatauluta toimenpide",
    cancel: "Peruuttaa",
    pleaseWait: "Ole hyvä ja odota",
    confirm: "Vahvista",
    booked: "Varattu",
    confirmed: "Vahvistettu",
    showed: "näytti",
    noShow: "Ei saapumista",
    cancelled: "peruutettu",
    unread: "Lukematon",
    engagement: "Sitoutumisvaihe",
    reaction: "Reaktio",
    deliverySummary: "Toimituksen yhteenveto",
    successfulDelivery: "Onnistunut toimitus",
    opened: "Auki",
    clicked: "Napsautettu",
    replied: "Vastasi",
    unsubscribed: "Peruutettu tilaus",
    complained: "Valitti",
    failed: "Epäonnistui",
    open: "Avoin",
    won: "Voitti",
    abandoned: "Hylätty",
    lost: "Kadonnut",
    sent: "Lähetetty",
    delivered: "Toimitettu",
    faceBookLeadForm: "Facebookin liidilomake",
    overdue: "Myöhässä",
    reviewsReceived: "Vastaanotetut arvostelut",
    formBuilders: "Lomakkeenrakentajat",
    switchToSaaS: "Vaihda SaaS:iin",
    numberOfContacts: "Yhteyksien lukumäärä",
    numberOfAppointments: "Tapaamisten määrä",
    outreach: "Ulottuvuus",
    notSubAccountUser: "Ei ali-tili käyttäjä",
    searchSubAccName: "Etsi alatilin nimellä",
    recipients: "Vastaanottajat",
    scheduleCreated: "Sähköpostiraportin aikataulu luotu kohteelle",
    scheduleUpdated: "Sähköpostiraportin aikataulu päivitetty kohteelle",
    scheduleNotCreated: "Sähköpostiraportin ajoitus ei voinut luoda kohteelle",
    scheduleNotUpdated: "Sähköpostiraportin aikataulua ei voitu päivittää kohteelle",
    total: "Kokonais-",
    inbound: "Tulossa",
    outbound: "Lähtevä",
    totalEmails: "Yhteensä sähköpostit",
    inboundEmails: "Saapuvat sähköpostit",
    outboundEmails: "Lähtevät sähköpostit",
    totalTextMessages: "Kokonaisviestit",
    inboundTextMessages: "Sisääntulevat tekstiviestit",
    outboundTextMessages: "Uloslähtevät tekstiviestit",
    totalCalls: "Kokonaispuhelut",
    inboundCalls: "Inbound-puhelut",
    outboundCalls: "Uläsopivät puhelut",
    faceBookLeadFormSubmissions: "Facebookin liidilomakkeen lähetykset",
    inboundGMBMessages: "Sisääntulevat GBP-viestit",
    outboundGMBMessages: "Lähtevät GBP-viestit",
    totalIgMessages: "Kokonaiset Instagram-viestit",
    inboundIgMessages: "Saapuvat Instagram-viestit",
    outboundIgMessages: "Ulos menevät Instagram-viestit",
    totalfbMessages: "Facebook-viestejä yhteensä",
    inboundfbMessages: "Sisään tulevat Facebook-viestit",
    outboundfbMessages: "Ulkopuoliset Facebook-viestit",
    sitesCreatedFunnels: "Luotuja verkkosivustoja - myyntitunnelit",
    sitesCreatedWebsites: "Sivustot luotu - Verkkosivut",
    sitesCreatedForms: "Sivustot luotu - Lomakkeet",
    sitesCreatedSurveys: "Sivustoja luotu - Kyselyt",
    googleReviews: "Google-arvostelut",
    facebookReviews: "Facebook-arvostelut",
    inboundCallDuration: "Saapuvan puhelun kesto (tunneissa)",
    outboundCallDuration: "Lähtevän puhelun kesto (tunnit)",
    pleaseSelect: "Ole hyvä ja valitse",
    subAccountIsDeleted: "Tämä alitili on poistettu",
    subAccountIsPaused: "Tämä alitili on keskeytetty",
    success: "Menestys",
    error: "Virhe",
    testEmailSent: "Testisähköposti lähetetty",
    testEmailNotSent: "Testisähköpostia ei voitu lähettää",
    typeToSearch: "Kirjoita etsiäksesi",
    reviewRequest: "Katselmuksen pyynnöt",
    pendingTransferDescription: "Muut virastot ovat pyytäneet siirtämään alatilit sinulle",
    pendingTransferHeading: "Sinulla on odottavia alitilien siirtopyyntöjä",
    subAccountTransfer: "Alitilien siirrot",
    manageSubAccTransfer: "Hallitse ala-tilisi siirto-pyyntöjä",
    subAccRequest: "Alitilin {tabName} pyynnöt",
    receive: "Vastaanottaa",
    send: "Lähetä",
    release: "Vapauta",
    requests: "{count} Pyyntöä",
    subAccLimit: "Alitilien käyttöraja saavutettu!",
    subAccPlanLimit: "Sinulla on käytössäsi 3/3 sallitusta alitilistä nykyisellä suunnitelmallasi",
    upgradeTime: "On aika päivittää!",
    upgradeHigher: "Päivitä korkeampaan suunnitelmaan hyväksyäksesi alitilisi siirron",
    browse: "Selaa suunnitelmia",
    pendingTransferTitle: "Siirtoanomus tälle alitilille on jo odottamassa",
    unableTransfer: "Ei voi siirtää alitiliä tähän virastoon",
    hippaErrorDescription:
      "Alitilisi, jota yrität siirtää, on HIPAA-ominaisuudella varustettu eikä sitä voi siirtää HIPAA-toimintaa harjoittamattomalle yritykselle",
    pendingTransferErrorDescription:
      "Ystävällisesti peruuta jo olemassa oleva siirtoapupyyntö tälle alitilille ennen uuden tekemistä",
    receivingAgency: "Vastaanottava viranomainen",
    releasingAgency: "Julkaisuorganisaatio",
    noOfContacts: "Yhteystietojen määrä",
    requestedDate: "Pyydetty päivämäärä",
    action: "Toiminta",
    cancelTransfer: "Peruuta siirto",
    decline: "Hylkääminen",
    cancelTransferRequest: "Peruuta alitilin siirtoon liittyvä pyyntö?",
    cancelRequestDescription:
      "Vastaanottava viranomainen ei enää näe tätä siirtohakemusta, eikä alitilin {name} varoja siirretä",
    declineRequest: "Kieltäydy alitilinsiirto-pyynnöstä?",
    declineRequestDescription: "Pyyntö hylätään, eikä alitiliä {name} siirretä toimistollesi",
    approveTransfer: "Hyväksyykö osatilin siirron toimistollesi?",
    approveTransferDescription: "{name} alitili siirretään virastollesi",
    transferNow: "Siirrä nyt",
    cancelNow: "Peruuta nyt",
    transferErrorDetails: "Alitiliä, jota yrität siirtää, ei voi siirtää",
    cancelRequest: "Peru pyyntö",
    received: "Saapunut",
    approve: "Hyväksy",
    goBack: "Palaa takaisin",
    done: "Valmis",
    subAccountTransferred: "Ala-tili on siirretty onnistuneesti!",
    subAccountTransferDescription:
      "Huomioi, että vaikka alitili on siirretty, tietyt varat, kuten käyttäjät, puhelinnumerot, Mailgun-tilit, Yext-listaukset jne., voivat kestää 10-15 minuuttia näkyäkseen kohteessa",
    activeSubscriptionsAlert:
      "Paikalla on aktiivisia tilauksia. Peru kaikki tilaukset ennen paikan siirtämistä. Aktiiviset tilaukset: {subscriptions}",
    emailEngagement: "Sähköposti osallistuminen",
    emailReaction: "Sähköpostireaktio",
    emailDeliverySummary: "Sähköpostin toimitusyhteenveto",
    facebook: "Facebook",
    sms: "SMS",
    instagram: "Instagram",
    accountSummarySubAccount: "Account Summary - {subAccountName}",
    test: "Test",
    scheduleExists: "Schedule Exists",
    aToZ: "A - Z",
    zToA: "Z - A",
    onlyAdminUsersCanAddNewSubAccounts: "Only Admin users can add new sub-account's.",
    locationName: "Location Name",
    subAccountDeletedIfChangedMind:
      "This Sub-Account will be deleted on {time}, if you've changed your mind",
    eject: "Poista",
    unableEjectCancel: "Ei voi peruuttaa ala-tilin poistopyyntöä",
    EjectErrorDetails: "Tilinlausunto poisto -pyyntöä, jonka yrität peruuttaa, ei voi peruuttaa",
    cancelEjectRequest: "Peruuta osatilin poistopyyntö?",
    cancelEjectRequestDescription:
      "Valittu käyttäjä ei enää pysty luomaan uutta toimistotiliä alitilin {name} kautta",
  },
};
