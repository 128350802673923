export default {
  fr_CA: {
    common: {
      thisWeek: "Cette semaine",
      lastWeek: "La semaine dernière",
      lastNumberOfDays: "Derniers {numberOfDays} jours",
      thisMonth: "Ce mois-ci",
    },
    subAccounts: "Sous-comptes",
    submitFeedback: "Soumettre des commentaires",
    createSubAccount: "Créer un sous-compte",
    viewScheduleReports: "Voir les rapports planifiés",
    searchSubAccount: "Recherche par sous-compte",
    columns: "Les Colonnes",
    sortBy: "Trier par",
    selectColumns: "Sélectionner des colonnes",
    maxColumns: "Max 8 colonnes à la fois",
    applyColumns: "Appliquer les colonnes",
    activeUsers: "Utilisateurs actifs",
    appointments: "Rendez-vous",
    contacts: "Contacts",
    emails: "Courriels",
    textMessages: "Messages texte",
    calls: "Appels",
    submissions: "Soumissions",
    reviews: "Avis",
    sitesCreated: "Sites Créés",
    name: "Nom",
    formSubmissions: "Soumissions de formulaire",
    surveySubmissions: "Soumissions d'enquête",
    facebookFormSubmissions: "Soumissions de formulaire Facebook",
    funnels: "Entonnoirs",
    websites: "Sites web",
    forms: "Formulaires",
    surveys: "Sondages",
    highestFirst: "Le plus élevé en premier",
    lowestFirst: "Le plus bas en premier",
    orderBy: "Commander par",
    noMoreLocations: "Aucun autre emplacement trouvé",
    preparingReport: "Nous préparons votre rapport",
    preparingReportDesc:
      "Ne fermez pas ou ne rechargez pas cette fenêtre pendant l'exportation en cours",
    outOf: "hors de",
    subAccountsExported: "Comptes secondaires exportés",
    close: "Fermer",
    scheduledForDeletion: "Prévu pour la suppression",
    readyToSell: "Prêt à vendre",
    closedWon: "Fermé (Gagné)",
    wpSaleSuccessful: "La vente sur WordPress est un succès",
    wpNotSold: "WordPress n'est pas encore vendu",
    yxtSaleSuccessful: "La vente de Yext est réussie",
    yxtNotSold: "Yext pas encore vendu",
    saasSubInactive: "L'abonnement SaaS est inactif",
    saasSubTrialing: "L'abonnement SaaS est en phase d'essai",
    saasSubActive: "L'abonnement SaaS est actif",
    emailRebillingActive: "La facturation par courrier électronique est active",
    emailRebillingInactive: "La facturation par courrier électronique est inactive",
    twilioRebillingActive: "Le réabonnement de Twilio est actif",
    twilioRebillingInactive: "La facturation récurrente de Twilio est inactive",
    wpRebillingActive: "La facturation renouvelée de WhatsApp est active",
    wpRebillingInactive: "La facturation automatique de WhatsApp est inactive",
    saasModeActivated: "Le mode SaaS est activé",
    paymentPending: "Le paiement est en attente",
    saasModeNotActivated: "Le mode SaaS n'est pas activé",
    managedByLCPhone: "Géré par LC Phone",
    migrationToLCPhone: "Sous migration vers LC Phone",
    notManagedByLCPhone: "Non géré par LC Phone",
    managedByLCEmail: "Géré par LC Courriel",
    notMangedByLCEmail: "Non géré par courriel LC",
    remainingWalletBalance: "Solde restant du portefeuille de ce lieu SaaS",
    reports: "Rapports",
    switchToSubAccount: "Passer au sous-compte",
    manageClient: "Gérer le client",
    subAccountDeleted: "Cette sous-compte sera supprimé le",
    subAccountDeletedInMinute: "Ce sous-compte sera supprimé dans une minute",
    changedMind: "si tu as changé d'avis",
    clickHere: "Cliquez ici",
    subAccountPaused:
      "Ce sous-compte est en pause. Vos clients ne peuvent pas se connecter, mais l'agence le peut. Pour réactiver ce compte",
    subAccountRestricted: "Ce sous-compte est en attente d'approbation, il est donc suspendu",
    subAccountVerificationPending:
      "Ce sous-compte est en pause en raison de la vérification en attente de l'e-mail et du téléphone par l'administrateur du sous-compte",
    subAccountDeclined:
      "Ce sous-compte est en pause en raison d'une demande d'approbation refusée. Vous pouvez toujours reprendre ce sous-compte à partir de",
    here: "ici",
    reviewAndResume: "revoir et reprendre ce sous-compte",
    incomingCalls: "Appels entrants",
    outgoingCalls: "Appels sortants",
    totalTalktime: "Temps de communication total",
    incomingEmails: "Courriels entrants",
    outgoingEmails: "Courriels sortants",
    incomingSms: "Nouveaux messages texte entrants",
    outgoingSms: "Messages sortants",
    contactsCreated: "Nombre de contacts créés",
    appointmentsBooked: "Nombre de rendez-vous réservés",
    totalFormSubmissions: "Total des soumissions de formulaire",
    totalSurveySubmissions: "Nombre total de soumissions d'enquête",
    totalFacebookFormSubmissions: "Total des soumissions de formulaire sur Facebook",
    incomingGMBMessages: "Messages entrants en GBP",
    outgoingGMBMessages: "Messages sortants GBP",
    gmbMessages: "Messages GPG",
    fbMessages: "Messages Facebook",
    incomingFbMessages: "Messages entrants sur Facebook",
    outgoingFbMessages: "Messages sortants sur Facebook",
    igMessages: "Messages Instagram",
    incomingIgMessages: "Messages Instagram entrants",
    outgoingIgMessages: "Messages sortants sur Instagram",
    totalReviews: "Nombre total d'avis",
    positiveReviews: "Avis positifs",
    negativeReviews: "Avis négatifs",
    back: "Derrière",
    selectDashboards: "Sélectionner les tableaux de bord",
    next: "Suivant",
    backToSubAccounts: "Retour aux sous-comptes",
    pauseSchedule: "Horaire de pause",
    resumeSchedule: "Reprise du calendrier",
    editSchedule: "Modifier l'horaire",
    addSchedule: "Ajouter un horaire",
    selectReportToAdd: "Sélectionnez les rapports à ajouter dans l'e-mail",
    selectAll: "Tout sélectionner",
    pauseScheduleConfirm:
      "Êtes-vous certain(e) de vouloir mettre en pause le programme de rapport ?",
    resumeScheduleConfirm: "Êtes-vous certain(e) de vouloir reprendre le programme de rapport?",
    tasks: "Tâches",
    conversations: "Conversations - Discussions",
    users: "Utilisateurs",
    dealOverview: "Aperçu de l'accord",
    confirmAction: "Confirmer l'action",
    yes: "Oui",
    no: "Non",
    reportDetails: "Détails du rapport",
    reportCreatedOn: "Rapport créé le",
    searchByEmail: "Recherche par courriel",
    lastActivity: "Dernière activité",
    noRecords: "Aucun dossier trouvé",
    scheduleReport: "Rapport d'horaire",
    schedule: "Horaire",
    save: "Sauver",
    emailSubject: "Objet du courriel",
    frequency: "Fréquence",
    daily: "Quotidien",
    weekly: "hebdomadaire",
    monthly: "Mensuellement",
    nextScheduleDate: "Prochaine date de planification",
    startingOn: "À partir de",
    timeOfDay: "Temps de la journée",
    reportsToTimezone: "Le rapport sera envoyé dans le fuseau horaire du sous-compte",
    selectRecipients: "Sélectionner les destinataires",
    invalidEmail: "Vous avez saisi une adresse e-mail invalide",
    testEmail: "Courriel de test",
    selectTestRecipients: "Sélectionnez les destinataires de test",
    setupSubject: "Sélectionnez le sujet ci-dessus pour tester le rapport",
    sendTest: "Envoyer le test",
    scheduleSimilarSchedules: "Planifier un rapport similaire pour d'autres sous-comptes",
    accountSummary: "Résumé de compte",
    scheduleList: "Liste d'horaire",
    subAccountName: "Nom du sous-compte",
    nextRunDate: "Prochaine date d'exécution",
    reportSectionsAdded: "Sections du rapport ajoutées",
    status: "Acceptation sociale",
    scheduleAction: "Action programmée",
    cancel: "Annuler",
    pleaseWait: "S'il vous plaît, attendez",
    confirm: "Confirmer",
    booked: "Réservé",
    confirmed: "Confirmé",
    showed: "Montré",
    noShow: "Absentation",
    cancelled: "Annulé",
    unread: "Non lu",
    engagement: "Engagement",
    reaction: "Réaction",
    deliverySummary: "Résumé de livraison",
    successfulDelivery: "Livraison réussie",
    opened: "Ouvert",
    clicked: "Cliqué",
    replied: "Répondu",
    unsubscribed: "Désabonné",
    complained: "se sont plaintes",
    failed: "Échoué",
    open: "Ouvert",
    won: "gagné",
    abandoned: "Abandonné",
    lost: "Perdu",
    sent: "envoyé",
    delivered: "Livré",
    faceBookLeadForm: "Formulaire de génération de leads de Facebook",
    overdue: "En retard",
    reviewsReceived: "Avis reçus",
    formBuilders: "Constructeurs de formulaires",
    switchToSaaS: "Passer à SaaS",
    numberOfContacts: "Nombre de contacts",
    numberOfAppointments: "Nombre de rendez-vous",
    outreach: "sensibilisation",
    notSubAccountUser: "Pas un utilisateur de sous-compte",
    searchSubAccName: "Recherche par nom de sous-compte",
    recipients: "Destinataires",
    scheduleCreated: "Rapport de planification d'envoi par courrier électronique créé pour",
    scheduleUpdated: "Courrier électronique Les rapports horaires mis à jour pour",
    scheduleNotCreated: "Le programme d'envoi du rapport par courriel n'a pas pu être créé pour",
    scheduleNotUpdated:
      "L'horaire de rapport par courrier électronique n'a pas pu être mis à jour pour",
    total: "Total",
    inbound: "Entrant",
    outbound: "Sortant",
    totalEmails: "Total des courriels",
    inboundEmails: "Courriels entrants",
    outboundEmails: "Courriels sortants",
    totalTextMessages: "Total des messages texte",
    inboundTextMessages: "Messages texte entrants",
    outboundTextMessages: "Messages sortants",
    totalCalls: "Appels totaux",
    inboundCalls: "Appels entrants",
    outboundCalls: "Appels sortants",
    faceBookLeadFormSubmissions: "Soumissions de formulaires de prospects sur Facebook",
    inboundGMBMessages: "Messages entrants en GBP",
    outboundGMBMessages: "Messages sortants GBP",
    totalIgMessages: "Total des messages Instagram",
    inboundIgMessages: "Messages Instagram entrants",
    outboundIgMessages: "Messages sortants Instagram",
    totalfbMessages: "Messages Facebook totaux",
    inboundfbMessages: "Messages entrants sur Facebook",
    outboundfbMessages: "Messages Facebook sortants",
    sitesCreatedFunnels: "Sites créés - Entonnoirs",
    sitesCreatedWebsites: "Sites créés - Sites Web",
    sitesCreatedForms: "Sites créés - Formulaires",
    sitesCreatedSurveys: "Sites créés - Sondages",
    googleReviews: "Avis Google",
    facebookReviews: "Avis Facebook",
    inboundCallDuration: "Durée des appels entrants (heures)",
    outboundCallDuration: "Durée des appels sortants (heures)",
    pleaseSelect: "S'il vous plaît sélectionner",
    subAccountIsDeleted: "Ce sous-compte a été supprimé",
    subAccountIsPaused: "Ce sous-compte a été mis en pause",
    success: "Succès",
    error: "Erreur",
    testEmailSent: "Courriel de test envoyé",
    testEmailNotSent: "L'email de test n'a pas pu être envoyé",
    typeToSearch: "Tapez pour rechercher",
    reviewRequest: "Demandes d'examen",
    pendingTransferDescription:
      "D'autres agences ont demandé de transférer des sous-comptes vers votre compte",
    pendingTransferHeading: "Vous avez des demandes de transfert de sous-comptes en attente",
    subAccountTransfer: "Transferts entre sous-comptes",
    manageSubAccTransfer: "Gérer vos demandes de transfert de sous-comptes",
    subAccRequest: "Demandes de sous-compte {tabName}",
    receive: "Recevoir",
    send: "Envoyer",
    release: "Libérer",
    requests: "{count} demandes",
    subAccLimit: "Limite d'utilisation des sous-comptes atteinte !",
    subAccPlanLimit: "Vous avez utilisé les 3 sous-comptes permis dans votre plan actuel",
    upgradeTime: "Il est temps pour une mise à niveau!",
    upgradeHigher:
      "Veuillez mettre à niveau vers un plan supérieur pour accepter le transfert du sous-compte",
    browse: "Parcourir les plans",
    pendingTransferTitle: "Une demande de transfert pour ce sous-compte est déjà en attente",
    unableTransfer: "Impossible de transférer le sous-compte à cette agence",
    hippaErrorDescription:
      "Le sous-compte que vous essayez de transférer est activé par HIPAA et ne peut pas être transféré à une agence non-HIPAA",
    pendingTransferErrorDescription:
      "Veuillez annuler la demande de transfert existante pour ce sous-compte avant de procéder à en soumettre une nouvelle",
    receivingAgency: "Agence de réception",
    releasingAgency: "Agence de mise en liberté",
    noOfContacts: "Nombre de contacts",
    requestedDate: "Date demandée",
    action: "Action",
    cancelTransfer: "Annuler le transfert",
    decline: "Déclin",
    cancelTransferRequest: "Annuler la demande de transfert de sous-compte ?",
    cancelRequestDescription:
      "L'agence réceptrice ne verra plus cette demande de transfert, et le sous-compte {name} ne sera pas transféré",
    declineRequest: "Refuser la demande de transfert de sous-compte?",
    declineRequestDescription:
      "La demande sera refusée et le sous-compte {name} ne sera pas transféré à votre agence",
    approveTransfer: "Approuver le transfert de sous-compte vers votre agence?",
    approveTransferDescription: "Le compte de sous-{name} sera transféré à votre agence",
    transferNow: "Transfert maintenant",
    cancelNow: "Annuler maintenant",
    transferErrorDetails:
      "Le sous-compte que vous essayez de transférer ne peut pas être transféré",
    cancelRequest: "Annuler la demande",
    received: "Reçu",
    approve: "Approuver",
    goBack: "Retourner",
    done: "Fini",
    subAccountTransferred: "Le sous-compte a été transféré avec succès!",
    subAccountTransferDescription:
      "Veuillez noter que même si le sous-compte a été transféré, certains actifs tels que les utilisateurs, les numéros de téléphone, les comptes Mailgun, les inscriptions Yext, etc. peuvent mettre 10 à 15 minutes pour apparaître dans la destination",
    activeSubscriptionsAlert:
      "L'emplacement a des abonnements actifs. Veuillez annuler tous les abonnements avant de transférer l'emplacement. Liste des abonnements actifs : {subscriptions}",
    emailEngagement: "Engagement par courriel",
    emailReaction: "Réaction par courriel",
    emailDeliverySummary: "Résumé de la livraison des courriels",
    facebook: "Facebook",
    sms: "SMS",
    instagram: "Instagram",
    accountSummarySubAccount: "Account Summary - {subAccountName}",
    test: "Test",
    scheduleExists: "Schedule Exists",
    aToZ: "A - Z",
    zToA: "Z - A",
    onlyAdminUsersCanAddNewSubAccounts: "Only Admin users can add new sub-account's.",
    locationName: "Location Name",
    subAccountDeletedIfChangedMind:
      "This Sub-Account will be deleted on {time}, if you've changed your mind",
    eject: "Éjecter",
    unableEjectCancel: "Impossible d'annuler la demande d'éjection de sous-compte",
    EjectErrorDetails:
      "La demande d'éjection du sous-compte que vous essayez d'annuler ne peut pas être annulée",
    cancelEjectRequest: "Annuler la demande de retrait du sous-compte?",
    cancelEjectRequestDescription:
      "L'utilisateur sélectionné ne pourra plus créer une nouvelle agence à partir du sous-compte {name}",
  },
};
