export default {
  es: {
    common: {
      thisWeek: "Esta semana",
      lastWeek: "La semana pasada",
      lastNumberOfDays: "Últimos {numberOfDays} días",
      thisMonth: "Este mes",
    },
    subAccounts: "Las cuentas secundarias",
    submitFeedback: "Enviar comentarios",
    createSubAccount: "Crear subcuenta",
    viewScheduleReports: "Ver informes programados",
    searchSubAccount: "Buscar por subcuenta",
    columns: "Columnas",
    sortBy: "Ordenar por",
    selectColumns: "Seleccionar columnas",
    maxColumns: "Máximo 8 columnas a la vez",
    applyColumns: "Aplicar columnas",
    activeUsers: "Usuarios activos",
    appointments: "Citas",
    contacts: "Contactos",
    emails: "Correos electrónicos",
    textMessages: "Mensajes de texto",
    calls: "Llamadas",
    submissions: "Presentaciones",
    reviews: "Reseñas",
    sitesCreated: "Sitios Creados",
    name: "Nombre",
    formSubmissions: "Envío de formularios",
    surveySubmissions: "Envíos de encuestas",
    facebookFormSubmissions: "Envío de formularios de Facebook",
    funnels: "Embudos",
    websites: "Sitios web",
    forms: "Formularios",
    surveys: "Encuestas",
    highestFirst: "Primero los más altos",
    lowestFirst: "El más bajo primero",
    orderBy: "Ordenar por",
    noMoreLocations: "No se encontraron más ubicaciones",
    preparingReport: "Estamos preparando tu informe",
    preparingReportDesc:
      "No cierres ni recargues esta ventana mientras se está realizando la exportación",
    outOf: "fuera de",
    subAccountsExported: "subcuentas exportadas",
    close: "Cerrar",
    scheduledForDeletion: "Programado para eliminación",
    readyToSell: "Listo para vender",
    closedWon: "Cerrado (ganado)",
    wpSaleSuccessful: "La venta de WordPress es exitosa",
    wpNotSold: "WordPress todavía no se ha vendido",
    yxtSaleSuccessful: "La venta de Yext es exitosa",
    yxtNotSold: "Yext todavía no se ha vendido",
    saasSubInactive: "La suscripción SaaS está inactiva",
    saasSubTrialing: "Suscripción a SaaS está siendo probada",
    saasSubActive: "la suscripción de SaaS está activa",
    emailRebillingActive: "La facturación por correo electrónico está activa",
    emailRebillingInactive: "La facturación por correo electrónico está inactiva",
    twilioRebillingActive: "La facturación de Twilio está activa",
    twilioRebillingInactive: "La facturación recurrente de Twilio está inactiva",
    wpRebillingActive: "WhatsApp el recargo automático está activo",
    wpRebillingInactive: "WhatsApp la facturación recurrente está inactiva",
    saasModeActivated: "Se activa el modo de SaaS",
    paymentPending: "El pago está pendiente",
    saasModeNotActivated: "El modo SaaS no está activado",
    managedByLCPhone: "Gestionado por LC Phone",
    migrationToLCPhone: "Bajo migración a LC Phone",
    notManagedByLCPhone: "No gestionado por LC Phone",
    managedByLCEmail: "Gestionado por LC Email",
    notMangedByLCEmail: "No gestionado por LC Email",
    remainingWalletBalance: "Saldo restante en la billetera de esta ubicación de SaaS",
    reports: "Informes",
    switchToSubAccount: "Cambiar a Subcuenta",
    manageClient: "Gestionar cliente",
    subAccountDeleted: "Esta subcuenta será eliminada el",
    subAccountDeletedInMinute: "Esta subcuenta se eliminará en un minuto",
    changedMind: "si has cambiado de opinión",
    clickHere: "Haz clic aquí",
    subAccountPaused:
      "Esta subcuenta está en pausa. Tus clientes no pueden iniciar sesión, pero la agencia sí puede. Para reactivar esta cuenta",
    subAccountRestricted: "Esta subcuenta está pausada debido a una aprobación pendiente",
    subAccountVerificationPending:
      "Esta subcuenta está pausada debido a la verificación pendiente del correo electrónico y el teléfono por parte del administrador de la subcuenta",
    subAccountDeclined:
      "Esta subcuenta está pausada debido a una solicitud de aprobación rechazada. Aún puedes reanudar esta subcuenta desde",
    here: "aquí",
    reviewAndResume: "Revisar y reanudar esta subcuenta",
    incomingCalls: "llamadas entrantes",
    outgoingCalls: "Llamadas salientes",
    totalTalktime: "Tiempo total de conversación",
    incomingEmails: "Correos electrónicos entrantes",
    outgoingEmails: "Correos electrónicos salientes",
    incomingSms: "Mensajes de texto entrantes",
    outgoingSms: "Mensajes de texto salientes",
    contactsCreated: "Número de contactos creados",
    appointmentsBooked: "Número de citas reservadas",
    totalFormSubmissions: "Total envíos de formularios",
    totalSurveySubmissions: "Total de envíos de encuestas",
    totalFacebookFormSubmissions: "Total de envíos de formulario de Facebook",
    incomingGMBMessages: "Mensajes entrantes de GBP",
    outgoingGMBMessages: "Mensajes salientes en GBP",
    gmbMessages: "Mensajes de GBP",
    fbMessages: "Mensajes de Facebook",
    incomingFbMessages: "Mensajes entrantes de Facebook",
    outgoingFbMessages: "Mensajes salientes de Facebook",
    igMessages: "Mensajes de Instagram",
    incomingIgMessages: "Mensajes entrantes de Instagram",
    outgoingIgMessages: "Mensajes salientes de Instagram",
    totalReviews: "Reseñas totales",
    positiveReviews: "Opiniones positivas",
    negativeReviews: "Reseñas negativas",
    back: "Espalda",
    selectDashboards: "Seleccionar paneles de control",
    next: "Siguiente",
    backToSubAccounts: "Volver a las subcuentas",
    pauseSchedule: "Pausar horario",
    resumeSchedule: "Reanudar horario",
    editSchedule: "Editar horario",
    addSchedule: "Agregar horario",
    selectReportToAdd: "Selecciona los informes para agregar en el correo electrónico",
    selectAll: "Seleccionar todo",
    pauseScheduleConfirm: "¿Estás seguro de que quieres pausar el programa de informes?",
    resumeScheduleConfirm: "¿Estás seguro/a de que quieres reanudar el programa de informes?",
    tasks: "Tareas",
    conversations: "Conversaciones",
    users: "Usuarios",
    dealOverview: "Resumen del acuerdo",
    confirmAction: "Confirmar acción",
    yes: "sí",
    no: "No",
    reportDetails: "Detalles del informe",
    reportCreatedOn: "Informe creado el",
    searchByEmail: "Buscar por correo electrónico",
    lastActivity: "Última actividad",
    noRecords: "No se encontraron registros",
    scheduleReport: "Informe de programación",
    schedule: "Horario",
    save: "Guardar",
    emailSubject: "Asunto del correo electrónico",
    frequency: "Frecuencia",
    daily: "Diario",
    weekly: "Semanal",
    monthly: "Mensual",
    nextScheduleDate: "Próxima fecha de programación",
    startingOn: "Empezando el",
    timeOfDay: "Hora del día",
    reportsToTimezone: "El informe se enviará en la zona horaria de la subcuenta",
    selectRecipients: "Seleccionar destinatarios",
    invalidEmail: "Has ingresado una dirección de correo electrónico no válida",
    testEmail: "Correo de prueba",
    selectTestRecipients: "Seleccionar destinatarios de prueba",
    setupSubject: "Configura el tema arriba para probar el informe",
    sendTest: "Enviar prueba",
    scheduleSimilarSchedules: "Programar un informe similar para otras subcuentas",
    accountSummary: "Resumen de cuenta",
    scheduleList: "Lista de horarios",
    subAccountName: "Nombre de subcuenta",
    nextRunDate: "Próxima Fecha de Ejecución",
    reportSectionsAdded: "Secciones del informe añadidas",
    status: "Estado",
    scheduleAction: "Acción programada",
    cancel: "Cancelar",
    pleaseWait: "Por favor, espere",
    confirm: "Confirmar",
    booked: "Reservado",
    confirmed: "Confirmado",
    showed: "Mostró",
    noShow: "No presentado",
    cancelled: "Cancelado",
    unread: "No leído",
    engagement: "Compromiso",
    reaction: "Reacción",
    deliverySummary: "Resumen de entrega",
    successfulDelivery: "Entrega exitosa",
    opened: "Abierto",
    clicked: "Hizo clic",
    replied: "respondió",
    unsubscribed: "Desinscrito",
    complained: "Quejado",
    failed: "Fallido",
    open: "Abrir",
    won: "ganó",
    abandoned: "Abandonado",
    lost: "Perdido",
    sent: "Enviado",
    delivered: "Entregado",
    faceBookLeadForm: "Formulario de Facebook Lead",
    overdue: "Vencido",
    reviewsReceived: "Opiniones recibidas",
    formBuilders: "Constructores de formularios",
    switchToSaaS: "Cambiar a SaaS",
    numberOfContacts: "Número de contactos",
    numberOfAppointments: "Número de citas",
    outreach: "Alcance",
    notSubAccountUser: "No es un usuario de subcuenta",
    searchSubAccName: "Buscar por nombre de subcuenta",
    recipients: "Recipients - Destinatarios",
    scheduleCreated: "Programa de informe por correo electrónico creado para",
    scheduleUpdated: "Programa de informes por correo electrónico actualizado para",
    scheduleNotCreated: "No se pudo crear el horario de informe por correo electrónico para",
    scheduleNotUpdated: "No se pudo actualizar el horario de informe por correo electrónico para",
    total: "Total",
    inbound: "Entrante",
    outbound: "Salida",
    totalEmails: "Total Correos Electrónicos",
    inboundEmails: "Correos entrantes",
    outboundEmails: "Correos electrónicos salientes",
    totalTextMessages: "Total mensajes de texto",
    inboundTextMessages: "Mensajes de texto entrantes",
    outboundTextMessages: "Mensajes de texto salientes",
    totalCalls: "Total de llamadas",
    inboundCalls: "Llamadas entrantes",
    outboundCalls: "Llamadas salientes",
    faceBookLeadFormSubmissions: "Envío de formularios de clientes potenciales de Facebook",
    inboundGMBMessages: "Mensajes entrantes en GBP",
    outboundGMBMessages: "Mensajes salientes de GBP",
    totalIgMessages: "Total de mensajes de Instagram",
    inboundIgMessages: "Mensajes entrantes de Instagram",
    outboundIgMessages: "Mensajes salientes de Instagram",
    totalfbMessages: "Total Mensajes de Facebook",
    inboundfbMessages: "Mensajes entrantes de Facebook",
    outboundfbMessages: "Mensajes de Facebook salientes",
    sitesCreatedFunnels: "Sitios Creados - Embudos",
    sitesCreatedWebsites: "Sitios creados - Sitios web",
    sitesCreatedForms: "Sitios creados - Formularios",
    sitesCreatedSurveys: "Sitios creados - Encuestas",
    googleReviews: "Reseñas de Google",
    facebookReviews: "Opiniones de Facebook",
    inboundCallDuration: "Duración de la llamada entrante (horas)",
    outboundCallDuration: "Duración de la llamada saliente (horas)",
    pleaseSelect: "Por favor, seleccione",
    subAccountIsDeleted: "Esta subcuenta ha sido eliminada",
    subAccountIsPaused: "Esta subcuenta ha sido pausada",
    success: "Éxito",
    error: "Error",
    testEmailSent: "Correo electrónico de prueba enviado",
    testEmailNotSent: "No se pudo enviar el correo de prueba",
    typeToSearch: "Escriba para buscar",
    reviewRequest: "Solicitudes de revisión",
    pendingTransferDescription: "Otras agencias han solicitado transferir subcuentas a usted",
    pendingTransferHeading: "Tienes solicitudes de transferencia de subcuentas pendientes",
    subAccountTransfer: "Transferencias de subcuentas",
    manageSubAccTransfer: "Administra tus solicitudes de transferencia de subcuenta",
    subAccRequest: "Solicitudes de subcuenta {tabName}",
    receive: "Recibir",
    send: "Enviar",
    release: "Lanzamiento",
    requests: "{count} solicitudes",
    subAccLimit: "¡Se ha alcanzado el límite de uso de subcuentas!",
    subAccPlanLimit: "Has utilizado los 3/3 subcuentas permitidas en tu plan actual",
    upgradeTime: "¡Es tiempo de una mejora!",
    upgradeHigher:
      "Por favor, actualice a un plan superior para aceptar la transferencia de cuenta secundaria",
    browse: "Explorar planes",
    pendingTransferTitle: "Una solicitud de transferencia para esta subcuenta ya está pendiente",
    unableTransfer: "No se puede transferir la subcuenta a esta agencia",
    hippaErrorDescription:
      "La subcuenta que estás tratando de transferir tiene habilitado el cumplimiento de la Ley HIPAA y no se puede transferir a una agencia que no cumple con la ley HIPAA",
    pendingTransferErrorDescription:
      "Por favor, cancele la solicitud de transferencia existente de esta subcuenta antes de proceder a crear una nueva",
    receivingAgency: "Agencia receptora",
    releasingAgency: "Agencia liberadora",
    noOfContacts: "Número de contactos",
    requestedDate: "Fecha solicitada",
    action: "Acción",
    cancelTransfer: "Cancelar transferencia",
    decline: "Declinación",
    cancelTransferRequest: "¿Cancelar solicitud de transferencia de subcuenta?",
    cancelRequestDescription:
      "La agencia receptora ya no verá esta solicitud de transferencia, y la subcuenta {nombre} no será transferida",
    declineRequest: "¿Rechazar solicitud de transferencia de subcuenta?",
    declineRequestDescription:
      "La solicitud será rechazada y la subcuenta {name} no se transferirá a su agencia",
    approveTransfer: "¿Aprobar la transferencia de subcuenta a su agencia?",
    approveTransferDescription: "La subcuenta de {name} será transferida a su agencia",
    transferNow: "Transferir ahora",
    cancelNow: "Cancelar ahora",
    transferErrorDetails: "La subcuenta que estás intentando transferir no se puede transferir",
    cancelRequest: "Cancelar solicitud",
    received: "Recibido",
    approve: "Aprobar",
    goBack: "Regresar",
    done: "Hecho",
    subAccountTransferred: "¡La subcuenta ha sido transferida exitosamente!",
    subAccountTransferDescription:
      "Por favor, tenga en cuenta que aunque la subcuenta haya sido transferida, algunos activos como usuarios, números de teléfono, cuentas de Mailgun, listados de Yext, etc. todavía pueden tardar de 10 a 15 minutos en reflejarse en el destino",
    activeSubscriptionsAlert:
      "La ubicación tiene suscripciones activas. Por favor, cancele todas las suscripciones antes de transferir la ubicación. Lista de suscripciones activas: {subscriptions}",
    emailEngagement: "Participación por correo electrónico",
    emailReaction: "Reacción por correo electrónico",
    emailDeliverySummary: "Resumen de entrega de correo electrónico",
    facebook: "Facebook",
    sms: "SMS",
    instagram: "Instagram",
    accountSummarySubAccount: "Account Summary - {subAccountName}",
    test: "Test",
    scheduleExists: "Schedule Exists",
    aToZ: "A - Z",
    zToA: "Z - A",
    onlyAdminUsersCanAddNewSubAccounts: "Only Admin users can add new sub-account's.",
    locationName: "Location Name",
    subAccountDeletedIfChangedMind:
      "This Sub-Account will be deleted on {time}, if you've changed your mind",
    eject: "Expulsar",
    unableEjectCancel: "No se puede cancelar la solicitud de expulsión de la subcuenta",
    EjectErrorDetails:
      "La solicitud de expulsión de la subcuenta que estás intentando cancelar no se puede cancelar",
    cancelEjectRequest: "¿Cancelar solicitud de expulsión de subcuenta?",
    cancelEjectRequestDescription:
      "El usuario seleccionado ya no podrá crear una nueva agencia a partir de la Subcuenta {name}",
  },
};
